import CompRebateLayoutTwo from '@/components/rebate/comp-rebate-layout-two/index.vue';
import RebateTable from '@/components/rebate/rebate-table/index.vue';
import rebateMixin from '@/mixins/nov-new-rebate';
import { getOutPay } from '@/service/rebate/novNewRebate';
import Rmb from '@/components/common/rmb/index.vue';

export default {
    name: 'nov-new-rebate-expend-award',
    mixins: [rebateMixin],
    components: {
        CompRebateLayoutTwo,
        RebateTable,
        Rmb,
    },
    data() {
        return {};
    },
    computed: {
        //  推荐奖总支出
        recommendColumns() {
            const { userInfo } = this;
            const columns = [
                {
                    label: '团队',
                    key: 'name',
                    render(name) {
                        return `${name}团队`;
                    },
                },
                {
                    label: '推荐奖他人奖励',
                    isRmb: true,
                    isNegative: true,
                    key: 'recommend',
                },
            ];
            //不是战略
            // if (userInfo.levelId !== "4") {
            //     columns[0].render = function render(value) {
            //         return `${value}团队`;
            //     };
            // }
            return columns;
        },
        //  达标奖总支出
        reachColumns() {
            const columns = [
                {
                    label: '团队',
                    key: 'name',
                    render(name) {
                        return `${name}团队`;
                    },
                },
                {
                    label: '达标奖他人奖励',
                    isRmb: true,
                    isNegative: true,
                    key: 'teamMarkAward',
                },
            ];
            return columns;
        },
        //  旅游总支出
        travelColumns() {
            const columns = [
                {
                    label: '团队',
                    key: 'name',
                    render(name) {
                        return `${name}团队`;
                    },
                },
                {
                    label: '旅游总他人奖励',
                    isRmb: true,
                    isNegative: true,
                    key: 'teamTravelAward',
                },
            ];
            return columns;
        },

        // 联创销售折扣支出
        lianchuangColumns() {
            const columns = [
                {
                    label: '团队',
                    key: 'name',
                    render(name) {
                        return `${name}团队`;
                    },
                },
                {
                    label: '联创销售折扣他人奖励',
                    isRmb: true,
                    isNegative: true,
                    key: 'teamDiscountSum',
                },
            ];
            return columns;
        },

        //  推荐奖总支出
        recommendList() {
            if (this.rebateInfo) {
                return this.rebateInfo.recommendList || [];
            }
            return [];
        },

        //  达标奖总支出
        reachList() {
            if (this.rebateInfo) {
                return this.rebateInfo.teamMarkList || [];
            }
            return [];
        },

        // 旅游总支出
        travelList() {
            if (this.rebateInfo) {
                return this.rebateInfo.travelAwardList || [];
            }
            return [];
        },

        // 联创销售折扣支出
        lianchuangList() {
            if (this.rebateInfo) {
                return this.rebateInfo.teamDiscountList || [];
            }
            return [];
        },

        //  推荐奖总支出
        recommendSum() {
            return this.recommendList.reduce((sum, item) => {
                return sum + item.recommend;
            }, 0);
        },

        //  达标奖总支出
        reachSum() {
            return this.reachList.reduce((sum, item) => {
                return sum + item.teamMarkAward;
            }, 0);
        },

        // 旅游总支出
        travelSum() {
            return this.travelList.reduce((sum, item) => {
                return sum + item.teamTravelAward;
            }, 0);
        },

        // 联创销售折扣支出
        lianchuangSum() {
            return this.lianchuangList.reduce((sum, item) => {
                return Number(sum) + Number(item.teamDiscountSum);
            }, 0);
        },
    },

    methods: {
        //  初始化页面
        init() {
            const {
                startTime,
                endTime,
                agentId,
                userInfo: { levelId },
                curActivityId,
                curSystemId,
            } = this;
            getOutPay({
                startTime,
                endTime,
                userId: agentId,
                levelId,
                systemId: curSystemId,
                actId: curActivityId,
            }).then(data => {
                this.rebateInfo = data;
            });
        },

        changeSelectedActivity(value) {
            this.curActivity = value;
        },

        changeSelectedSystem(value) {
            this.curSystem = value;
        },
    },
};
