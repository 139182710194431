/*
 * @Author: fengyanjing
 * @Date: 2020-06-04 17:06:56
 * @Description:
 * @FilePath: /frontend/src/components/rebate/comp-rebate-layout-two/index.js
 */
import UserInfo from '@/components/rebate/user-info/index.vue';
import MonthPicker from '@/components/rebate/month-picker/index.vue';
import ActivityPicker from '@/components/rebate/activity-picker/index.vue';
import SystemPicker from '@/components/rebate/system-picker/index.vue';
import Rmb from '@/components/common/rmb/index.vue';

export default {
    name: 'CompRebateLayoutTwo',
    components: {
        UserInfo,
        MonthPicker,
        ActivityPicker,
        SystemPicker,
        Rmb,
    },
    model: {
        prop: 'selectedDate',
        event: 'onSelectedDateChange',
    },
    props: {
        userInfo: {
            required: true,
            type: Object,
            default: () => {
                return {
                    userName: '吴亦凡',
                    level: '股东',
                    avatar: 'http://pics.sc.chinaz.com/files/pic/pic9/201509/apic14546.jpg',
                };
            },
        },
        minDate: {
            required: false,
            default: () => new Date(2023, 4), // 最小日期：2020-04
        },
        selectedDate: {
            //  当前日期选择器选中的时间
            required: true,
            default: () => new Date(),
        },
        selectedActivity: {
            required: true,
            default: () => {},
        },
        selectedSystem: {
            required: true,
            default: () => {},
        },
        activityList: {
            required: true,
            default: () => [],
        }
    },
    methods: {
        //  选中的日期发生了变化
        changeSelectedDate(value) {
            this.$emit('onSelectedDateChange', value);
        },

        changeSelectedActivity(value) {
            this.$emit('onSelectedActivityChange', value);
        },

        changeSelectedSystem(value) {
            this.$emit('onSelectedSystemChange', value);
        },

    },
};
